import './HMedia.scss';

import * as tsx from 'vue-tsx-support';
import { VNode } from 'vue';
import { createMediaModulePropsOptions } from './schemes';
import { HSimpleMedia } from './HSimpleMedia';
import { HListMedia } from './HListMedia';
import { HCarouselMedia } from './HCarouselMedia';
import { mergeVNodeData } from '~/helpers';

const ComponentAt = {
  simple: HSimpleMedia,
  carousel: HCarouselMedia,
  list: HListMedia,
};

export const HMedia = tsx.component({
  name: 'HMedia',
  functional: true,
  props: createMediaModulePropsOptions(),
  render(h, { props, data }): VNode {
    const Component = ComponentAt[props.value.type];
    return h(
      Component,
      mergeVNodeData(data, {
        staticClass: 'h-media-module',
        props,
      }),
    );
  },
});
