import Vue from 'vue';
import { Context, Plugin, NuxtAppOptions } from '@nuxt/types';
import {
  ExchangeRate,
  ActivityCommons,
  ActivityTargetAge,
  ActivityTargetAgeOption,
  RestaurantCommons,
  HotelRoomCommons,
  PaymentCommons,
  GalleryCommons,
  HotelBasicInfo,
  HotelBrandBasicInfo,
} from '~/schemes';

// @FIXME デプロイトリガーのための意味のないコメント

declare module 'vue/types/vue' {
  export interface Vue {
    $commons: CommonsService;
  }
}

declare module 'vuex/types' {
  export interface Store<S> {
    $commons: CommonsService;
  }
}

declare module '@nuxt/types' {
  export interface NuxtAppOptions {
    $commons: CommonsService;
  }

  export interface Context {
    $commons: CommonsService;
  }
}

/**
 * アクティビティ検索対象年齢の定数管理
 * 0~1歳
 * 2~6歳
 * 7~12歳
 */
export const ACTIVITY_TARGET_AGE_RANGES = [
  [0, 1],
  [2, 6],
  [7, 12],
];

export class CommonsService {
  readonly context: Context;

  get currency(): string {
    return this.context.store.getters['commons/currency'];
  }

  get currencyHotelIntroduction(): string {
    return this.context.store.getters['commons/currencyHotelIntroduction'];
  }

  get exchangeRates(): ExchangeRate[] {
    return this.context.store.state.commons.exchangeRates;
  }

  get currentExchangeRate(): ExchangeRate | undefined {
    return this.context.store.getters['commons/currentExchangeRate'];
  }

  get activity(): ActivityCommons | null {
    return this.context.store.state.commons.activity;
  }

  get restaurant(): RestaurantCommons | null {
    return this.context.store.state.commons.restaurant;
  }

  get room(): HotelRoomCommons {
    return this.context.store.state.commons.room;
  }

  get payment(): PaymentCommons {
    return this.context.store.state.commons.payment;
  }

  get gallery(): GalleryCommons {
    return this.context.store.state.commons.gallery;
  }

  get galleryCategories() {
    return this.gallery.galleryCategories;
  }

  get creditCards() {
    return this.payment.creditCards;
  }

  get payments() {
    return this.payment.payments;
  }

  get brands(): HotelBrandBasicInfo[] {
    return this.context.store.state.commons.brands;
  }

  get hotels(): HotelBasicInfo[] {
    return this.context.store.state.commons.hotels;
  }

  constructor(context: Context) {
    this.context = context;
  }

  getActivityTargetAges() {
    const { $i18n } = this.context;
    const options: ActivityTargetAgeOption[] = [
      ...ACTIVITY_TARGET_AGE_RANGES.map((_range) => {
        const [from, to] = _range;
        const range = { from, to };
        return {
          name: $i18n.t('value.yearsRange', range) as string,
          value: `${ActivityTargetAge.Range}-${from}-${to}`,
          range,
        };
      }),
      // 親子
      {
        name: $i18n.t('label.parentAndChild') as string,
        value: ActivityTargetAge.ParentAndChild,
        range: null,
      },
      // 大人
      {
        name: $i18n.t('label.jpAdult') as string,
        value: ActivityTargetAge.Adult,
        range: null,
      },
      // 年齢制限なし
      {
        name: $i18n.t('label.forAllAges') as string,
        value: ActivityTargetAge.All,
        range: null,
      },
    ];
    return options;
  }

  exchangeRateAt(currency): ExchangeRate | undefined {
    return this.context.store.getters['commons/exchangeRateAt'](currency);
  }

  changeCurrency(currency: string): Promise<void> {
    return this.context.store.dispatch('commons/changeCurrency', currency);
  }

  async getActivity() {
    let { activity } = this;
    if (!activity) {
      activity = await this.context.$api.commons.getActivityCommons();
      if (!activity) throw new Error('missing activity commons');
      this.context.store.commit('commons/SET_ACTIVITY', activity);
    }
    return activity;
  }

  async getRestaurant() {
    let { restaurant } = this;
    if (!restaurant) {
      restaurant = await this.context.$api.commons.getRestaurantCommons();
      if (!restaurant) throw new Error('missing restaurant commons');
      this.context.store.commit('commons/SET_RESTAURANT', restaurant);
    }
    return restaurant;
  }
}

const plugin: Plugin = (context, inject) => {
  const service = new CommonsService(context);
  context.$commons = service;
  inject('commons', service);
};

export default plugin;
