import './HLinkButton.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { BUTTON_THEME_SETTINGS_MAP } from '@/plugins/component-theme';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import { AvailableLanguage, LinkButtonModule } from '~/schemes';

export const HLinkButton = tsx.component({
  name: 'HLinkButton',
  functional: true,
  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<LinkButtonModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
    /** コンテナクラスの付与を無効にする場合に指定する */
    disableContainer: Boolean,
  },

  render(h, { props }): VNode {
    const { text, url, colorTheme } = props.value;
    const { disableContainer } = props;

    const {
      bgColor,
      color,
      boxShadow,
      borderRadius,
    } = BUTTON_THEME_SETTINGS_MAP[colorTheme];

    const targetValue = url && url.blank ? '_blank' : '_self';
    const href = url && url.url;

    return (
      <div
        staticClass="v-link-button"
        class={!disableContainer && 'h-container'}>
        <a
          staticClass="v-link-button__item"
          style={`background:var(${bgColor});
            color:var(${color});
          box-shadow:var(${boxShadow});
          border-radius:var(${borderRadius});`}
          href={href}
          target={targetValue}>
          {text}
        </a>
      </div>
    );
  },
});
