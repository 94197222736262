import './HSimpleDotPagination.scss';

import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Swiper from 'swiper';
import type { SwiperOptions } from 'swiper';
import { HIcon } from '../HIcon/HIcon';
import { HMediaOverlayCaption } from '../HMediaOverlayCaption';
import { HotelFeatureItemImage } from '~/pages/_lang/hotels/_hotel_slug/index/-components/MyHotelFeature/MyHotelFeatureItem';

export interface HSimpleDotPaginationProps {
  items: HotelFeatureItemImage[];
}

export interface HSimpleDotPaginationEmits {}

export interface HSimpleDotPaginationScopedSlots {}

interface SwiperElement extends HTMLElement {
  swiper: Swiper;
}
/**
 * ページネーションスライド
 */
@Component<HSimpleDotPaginationRef>({
  name: 'HSimpleDotPagination',

  beforeDestroy() {
    const { mainSwiper } = this;
    mainSwiper && mainSwiper.destroy(true, true);
  },

  render() {
    const { items } = this;
    return (
      <div staticClass="h-simple-dot-pagination">
        <div
          staticClass="swiper-container"
          key="main"
          ref="main"
          {...{
            directives: [
              {
                name: 'swiper',
                arg: 'mainSwiper',
                value: this.mainOptions,
              },
            ],
          }}>
          <div staticClass="swiper-wrapper">
            {items.map((item) => (
              <div staticClass="swiper-slide">
                <img
                  staticClass="h-simple-dot-pagination__img swiper-lazy"
                  data-src={this.$res.img(item.image)}
                />
                {item.meta && item.meta.caption && (
                  <HMediaOverlayCaption
                    modelValue={{
                      caption: item.meta.caption,
                    }}
                  />
                )}
                <div class="swiper-lazy-preloader"></div>
              </div>
            ))}
          </div>
          <div class="swiper-pagination"></div>
          {this.isHoshinoya && this.$mq.match.wide && (
            <div class="swiper-button">
              <HIcon
                staticClass="swiper-button-prev"
                name="keyboard-arrow-left"
              />
              <HIcon
                staticClass="swiper-button-next"
                name="keyboard-arrow-right"
              />
            </div>
          )}
        </div>
      </div>
    );
  },
})
export class HSimpleDotPaginationRef
  extends Vue
  implements HSimpleDotPaginationProps {
  $refs!: {
    main: SwiperElement;
  };

  mainSwiper?: Swiper;

  @Prop({ type: Array, required: true })
  readonly items!: HotelFeatureItemImage[];

  get mainOptions(): SwiperOptions {
    return {
      // 遅延読込
      lazy: true,
      // ページネーション（ドット）
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
      // スライド間の余白
      spaceBetween: 8,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      effect: 'fade',
      speed: 900,
      // スライドが1枚より多い時にドットマークを表示
      watchOverflow: true,
    };
  }

  get isHoshinoya() {
    return this.$theme.current.name === 'hoshinoya';
  }
}

export const HSimpleDotPagination = tsx
  .ofType<
    HSimpleDotPaginationProps,
    HSimpleDotPaginationEmits,
    HSimpleDotPaginationScopedSlots
  >()
  .convert(HSimpleDotPaginationRef);
