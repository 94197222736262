import { isAvailableLanguage, AvailableLanguage } from '~/schemes';

/** 5言語以外がブラウザ言語で設定された場合のデフォルトの言語 */
const DEFAULT_GLOBAL_LANGUAGE = 'en';

/**
 * 言語コードを任意の形に整形する
 * fullFormatがtrueに設定されていた場合、中国語がzh_cnやzh_twの表現で返される。
 * zhだけ取得して何某かの処理をしているところがあるかも知れないのでこの表現にしたが、
 * 不要であれば全てfullFormatの形式で返してもいいと思っている。
 * */
export function roundLanguage(
  language: string | null | undefined,
  fullFormat: boolean = false,
): string {
  if (!language) {
    return '';
  }

  if (fullFormat) {
    return language.toLocaleLowerCase().replace(/-/g, '_');
  }

  return language.split(',')[0].toLocaleLowerCase().substring(0, 2);
}

/**
 * GFの言語idに正規化する
 * @return {AvailableLanguage}
 */
export function normalizedGFLanguage(language: string): AvailableLanguage {
  language = roundLanguage(language, true);

  if (!isAvailableLanguage(language)) {
    return DEFAULT_GLOBAL_LANGUAGE;
  }

  return language as AvailableLanguage;
}
