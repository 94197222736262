import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelFeatureItemImage } from '../MyHotelFeature/MyHotelFeatureItem';
import { HotelDetail, HotelRoom, Gallery } from '~/schemes';
import {
  HRoomDetail,
  HIcon,
  IconName,
  HPlainBanner,
  HSimplePagination,
  HSimplePaginationItem,
  HSimpleDotPagination,
} from '~/components';

/**
 * @see: HRoomDetail
 */
interface MyItem {
  key: string;
  icon: IconName;
  body: VNodeChildren;
  wrap?: boolean;
}

export interface MyHotelGuestroomItemProps {
  hotel: HotelDetail;
  data: HotelRoom;

  /**
   * GAへのイベント送信に利用するID
   */
  eventId: string;

  /**
   * ゲストルーム構成内での自身のインデックス
   * これ本当は不要なんだけど、WordPressからの移行にあたり、アンカーリンクが移行できなかったので、仕方なく設けている
   *
   * @see https://hr-dev.backlog.jp/view/ACCO_CMS-637
   */
  roomIndex: number;
}

export interface MyHotelGuestroomItemEmits {}

export interface MyHotelGuestroomItemScopedSlots {}

@Component<MyHotelGuestroomItemRef>({
  name: 'MyHotelGuestroomItem',
  render() {
    const {
      image,
      data,
      headerItems,
      hotel,
      additionalContent,
      paginationItems,
      paginationDotItems,
    } = this;
    return (
      <div staticClass="my-hotel-guestroom-item">
        {!!image && !this.$theme.is('hoshinoya') && (
          <HSimplePagination
            staticClass="my-hotel-guestroom-item__img"
            items={paginationItems}
          />
        )}

        {!!image && this.$theme.is('hoshinoya') && (
          <HSimpleDotPagination
            staticClass="my-hotel-guestroom-item__img"
            items={paginationDotItems}
          />
        )}

        <div staticClass="my-hotel-guestroom-item-content">
          <div staticClass="my-hotel-guestroom-item-content__body">
            <h3 staticClass="my-hotel-guestroom-item__name">{data.name}</h3>
            <ul staticClass="my-hotel-guestroom-item__header">
              {headerItems.map((item) => (
                <li
                  key={item.key}
                  staticClass="my-hotel-guestroom-item__header__item">
                  <HIcon
                    name={item.icon}
                    staticClass="my-hotel-guestroom-item__header__item__icon"
                  />
                  <div
                    v-wysiwyg={item.body}
                    staticClass="my-hotel-guestroom-item__header__item__body"
                    class={{
                      'my-hotel-guestroom-item__header__item__body--wrap':
                        item.wrap,
                    }}
                  />
                </li>
              ))}
            </ul>
            <div
              v-wysiwyg={data.description}
              staticClass="my-hotel-guestroom-item__description"
            />

            <HRoomDetail
              staticClass="my-hotel-guestroom-item-content__detail"
              hotel={hotel}
              room={data}
            />
          </div>

          {!!additionalContent && !!additionalContent.text && (
            <HPlainBanner
              color="primary"
              staticClass="my-hotel-guestroom-item-content__additional-content"
              href={additionalContent.url}
              target="_blank"
              rel="noopener">
              {additionalContent.text}
            </HPlainBanner>
          )}
        </div>
      </div>
    );
  },
})
export class MyHotelGuestroomItemRef
  extends Vue
  implements MyHotelGuestroomItemProps {
  @Prop({ type: Object, required: true }) hotel!: HotelDetail;
  @Prop({ type: Object, required: true }) data!: HotelRoom;

  /**
   * これは本当は不要なprop
   *
   * @see MyHotelGuestroomItemProps
   */
  @Prop({ type: Number, required: true }) roomIndex!: number;

  /**
   * GAへのイベント送信に利用するID
   */
  @Prop({ type: String, required: true }) eventId!: string;

  get galleries(): Gallery[] {
    return this.data.images.map((image, i) => {
      return {
        id: String(i),
        categoryId: 'default',
        ...image,
      };
    });
  }

  get image(): string | null {
    const { galleries } = this;
    const gallery = galleries[0];
    return gallery ? gallery.xl : null;
  }

  get headerItems(): MyItem[] {
    const { data: room } = this;
    const { capacity, size } = room;

    const items: MyItem[] = [];
    {
      const { min, max } = capacity;
      if (min != null || max != null) {
        let capacityBody = min == null ? '' : `${capacity.min}`;
        if (max != null && capacity.min !== capacity.max) {
          capacityBody += `${this.$t('chore.dash')}${capacity.max}`;
        }
        capacityBody = this.$t('value.guestsRange', {
          guestsRange: capacityBody,
        }) as string;
        items.push({
          key: 'capacity',
          icon: 'peoples',
          body: capacityBody,
        });
      }
    }

    {
      const { min, max } = size;
      if (min != null || max != null) {
        let sizeBody = min == null ? '' : `${size.min}m²`;
        if (max != null && size.min !== size.max) {
          sizeBody += `${this.$t('chore.dash')}${size.max}m²`;
        }
        items.push({
          key: 'size',
          icon: 'expand-arrows',
          body: sizeBody,
        });
      }
    }

    if (room.bedType) {
      items.push({
        key: 'beds',
        icon: 'bed',
        body: room.bedType,
        wrap: true,
      });
    }
    return items;
  }

  get additionalContent() {
    return this.data.additionalContent;
  }

  get paginationItems(): HSimplePaginationItem[] {
    return this.data.images.map((image) => ({ image: image.xl }));
  }

  get paginationDotItems(): HotelFeatureItemImage[] {
    return this.data.images.map((image) => ({ image: image.xl }));
  }
}

export const MyHotelGuestroomItem = tsx
  .ofType<
    MyHotelGuestroomItemProps,
    MyHotelGuestroomItemEmits,
    MyHotelGuestroomItemScopedSlots
  >()
  .convert(MyHotelGuestroomItemRef);
