import './HDrawer.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNodeChildren } from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { bodyScrollLock, clickOutside } from '@dadajam4/vue-stack';
import { scrollToTop } from '@dadajam4/scroller';
import { HDrawerMenuGroup } from './HDrawerMenuGroup';
import { HDrawerMenuData } from './HDrawerMenu';
import {
  HPortalFooterSpacer,
  HBtn,
  HIconLink,
  HIcon,
  toBannerLinkProps,
  HBannerLink,
  IconName,
  HLoginDialog,
  HLoginDialogRef,
  HGlobalsDialog,
  HGlobalsDialogRef,
  HGlobalBaloonDialog,
  HGlobalBaloonDialogRef,
} from '~/components';
import {
  DEFAULT_SNS_LIST,
  SNSItem,
} from '~/pages/_lang/hotels/_hotel_slug/index/contact';
import {
  BannerLink,
  BannerWithProps,
  MAX_BANNER_COUNT_MAP,
  normalizeHotelFlowLineSettings,
} from '~/schemes';
import { limitArrayLength } from '~/helpers';

export * from './HDrawerMenuGroup';

export interface HDrawerProps {}

export interface HDrawerEmits {}

export interface HDrawerScopedSlots {}

const MY_MODALS = ['globals', 'account', 'baloon'] as const;

type MyModalName = typeof MY_MODALS[number];

// eslint-disable-next-line no-undef
type JSXElement = VueTsxSupport.JSX.Element;

interface MyMenu {
  key: string;
  label: string;
  icon: IconName;
  click: (ev: MouseEvent) => any;
  ballon?: () => VNodeChildren | JSXElement;
}

@Component<HDrawerRef>({
  name: 'HDrawer',
  directives: {
    bodyScrollLock,
    clickOutside,
  },
  provide() {
    return {
      drawer: this,
    };
  },
  beforeDestroy() {
    this.closeModal();
  },
  watch: {
    '$mq.match.narrow'() {
      this.closeModal();
    },
  },
  render() {
    const { brandLink, localMenus, snsList, bannerWithProps, linkMenus } = this;

    return (
      <aside staticClass="h-drawer">
        <transition name="fade">
          {this.isActive && (
            <div
              staticClass="h-drawer__overlay"
              onClick={(ev) => {
                ev.stopPropagation();
                this.isActive = false;
              }}
            />
          )}
        </transition>

        <transition
          name="h-drawer"
          onBeforeEnter={this.onDrawerBeforeEnter}
          onAfterLeave={this.onDrawerAfterLeave}>
          <div
            v-show={this.isActive}
            staticClass="h-drawer__content"
            style={this.offsetStyles}>
            <div
              ref="scroller"
              v-body-scroll-lock={this.isActive}
              staticClass="h-drawer__scroller">
              {/* ローカルナビゲーションエリア */}
              {!!localMenus && (
                <HDrawerMenuGroup
                  key="local"
                  staticClass="h-drawer__group h-drawer__group--local"
                  menus={localMenus}
                  invert
                  onAnchor={this.close}
                />
              )}

              <div
                staticClass="h-drawer-container"
                class={{
                  'h-drawer-container--excludeLocalMenu':
                    localMenus.length === 0,
                }}>
                {/* 関連情報エリア */}
                {/* 施設ページかつブランドナビゲーションテキストが設定されている場合にブランドページへ遷移するナビゲーションを表示 */}
                {!!brandLink && this.hotel && (
                  <HBtn
                    staticClass="h-drawer__group h-drawer__group--related"
                    key="brandLink"
                    to={brandLink.to}
                    href={brandLink.href}
                    target={brandLink.target}
                    color="plain">
                    {brandLink.text}
                  </HBtn>
                )}
                {/* 星野リゾート TOP */}
                <HBtn
                  staticClass="h-drawer__group h-drawer__group--related"
                  key="globalLink"
                  href={this.$t('url.globalTop') as string}
                  color="plain">
                  {this.$t('action.hoshinoResortsTop')}
                </HBtn>

                {/* ユーティリティエリア */}
                <div staticClass="h-drawer__utility">
                  {this.menus.map(({ key, label, icon, click }) => (
                    <HBtn
                      staticClass="h-drawer__utility__button"
                      key={key}
                      color="plain"
                      stackIcon={icon}
                      prependStack
                      onClick={click}>
                      {label}
                    </HBtn>
                  ))}
                </div>

                {/* SNS */}
                {!!snsList.length && (
                  <div staticClass="h-drawer__sns-list">
                    {snsList.map((sns) => (
                      <HIconLink
                        staticClass="h-drawer__sns-list__item"
                        class={`h-drawer__sns-list__item--${sns.type}`}
                        icon={sns.icon}
                        href={sns.url}
                        target="_blank"
                        rel="noopener"
                        hiddenExternalIcon></HIconLink>
                    ))}
                  </div>
                )}

                {/* バナー */}
                {!!bannerWithProps.length && (
                  <div staticClass="h-drawer__banner-link">
                    {bannerWithProps.map(({ banner, media }) => (
                      <HBannerLink
                        key={banner.key}
                        props={{
                          ...banner,
                          excludeDecoration: true,
                        }}
                        staticClass="h-drawer__banner-link__item"
                        style={
                          media
                            ? {
                                '--image-335x85': `url(${media['670x170']})`,
                              }
                            : { '--banner-image': 'gray' }
                        }
                      />
                    ))}
                  </div>
                )}

                {/* グローバルリンク */}
                <ul staticClass="h-drawer__global-links">
                  {linkMenus.map(({ label, href, target, hidden }) => {
                    return (
                      !hidden && (
                        <li staticClass="h-drawer__global-links__item">
                          <a href={href} target={target}>
                            {label}
                          </a>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>

              {/* マーケティング */}
              {this.$language.current === 'ja' && (
                <div staticClass="h-drawer__marketing">
                  {/* 日本語以外は非表示 */}
                  <h4 staticClass="h-drawer__marketing__title">
                    星野リゾート メールマガジン
                  </h4>
                  <p staticClass="h-drawer__marketing__sub-title">
                    ここでしか読めない、旅を楽しくするヒントを受け取りませんか？
                  </p>
                  <HBtn
                    staticClass="h-drawer__marketing__register"
                    color="plain"
                    href={this.$t('url.mailMagazine') as string}
                    target="_blank">
                    メールマガジン登録に進む
                  </HBtn>
                </div>
              )}

              {/* メニューを閉じる */}
              <div staticClass="h-drawer__close">
                <button
                  staticClass="h-drawer__close__button"
                  type="button"
                  onClick={this.close}>
                  <HIcon staticClass="h-drawer__close__icon" name={'close'} />
                  {this.$t('action.closeMenu')}
                </button>
              </div>

              <HPortalFooterSpacer />
            </div>
          </div>
        </transition>

        {/* 言語/通貨表示ダイアログ(PC) */}
        <HGlobalsDialog ref="globals"></HGlobalsDialog>

        {/* 言語/通貨表示ダイアログ(SP) */}
        <HGlobalBaloonDialog ref="baloon"></HGlobalBaloonDialog>

        {/* ログインダイアログ */}
        <HLoginDialog ref="account"></HLoginDialog>
      </aside>
    );
  },
})
export class HDrawerRef extends Vue implements HDrawerProps {
  $refs!: {
    scroller: HTMLElement;
    account: HLoginDialogRef;
    baloon: HGlobalBaloonDialogRef;
    globals: HGlobalsDialogRef;
  };

  get hotel() {
    return this.$hotel.current;
  }

  get accessSettings() {
    return this.hotel && this.hotel.accessSettings;
  }

  get flowLineSettings() {
    return this.hotel && this.hotel.flowLineSettings;
  }

  get brand() {
    return (this.hotel && this.hotel.brand) || this.$hotel.currentBrand;
  }

  get brandLink() {
    const { brand } = this;
    if (!brand) return;
    const { urlText } = brand;
    if (!urlText) return;
    let { url } = brand;
    if (!url) {
      url = `/${this.$language.current}/brands/${brand.slug}/`;
    }

    const loc = this.$navigation.resolveHrefTo(url);

    return {
      ...loc,
      text: urlText,
    };
  }

  /** SNS情報 */
  get snsList(): SNSItem[] {
    const { hotel } = this;
    if (!hotel) return [];
    const list = DEFAULT_SNS_LIST;

    // 自施設のSNSで上書き
    list.forEach((sns) => {
      const value = hotel[sns.type];
      if (value) {
        if (sns.type === 'facebook') {
          sns.url = `https://www.facebook.com/${value}`;
          sns.label = sns.url;
        } else if (sns.type === 'instagram') {
          sns.url = `https://www.instagram.com/${value}/`;
          sns.label = `@${value}`;
        } else if (sns.type === 'twitter') {
          sns.url = `https://twitter.com/${value}`;
          sns.label = `@${value}`;
        } else if (sns.type === 'youtube') {
          sns.url = `https://www.youtube.com/${value}`;
          sns.label = `@${value}`;
        } else {
          throw new Error('missing sns type');
        }
      }
    });
    return list;
  }

  get localMenus(): HDrawerMenuData[] {
    const { hotel } = this;
    if (!hotel) return [];
    const menus = [...this.$navigation.locals];

    if (hotel) {
      const location = this.$hotel.location();
      menus.unshift({
        key: 'top',
        label: this.$t('value.pageHomeWithName', {
          name: hotel.name,
        }) as string,
        to: location,
      });

      // 連絡先ページ追加
      const contactPath = `${location.path}contact/`;
      menus.push({
        key: 'contact',
        label: this.$t('name.contact') as string,
        href: contactPath,
        to: contactPath,
      });
    }

    return menus;
  }

  /** 表示するバナーの最大数 */
  get maxBannerCount(): number {
    return MAX_BANNER_COUNT_MAP.menu;
  }

  /**
   * ブランドバナー情報
   * @remarks
   * - 最大2つのブランドバナーを返却する
   */
  get brandBanner(): BannerLink[] {
    const { brand, maxBannerCount } = this;
    if (!brand) return [];
    const brandBanners = brand.banners as BannerLink[];
    return brandBanners.length > maxBannerCount
      ? limitArrayLength(brandBanners, maxBannerCount)
      : brandBanners;
  }

  /**
   * 正規化済みのメニューバナー情報
   * @remarks
   * - 最大2つのメニューバナーを返却する
   * - 正規化済みのバナーが存在しない場合はブランドバナーを返却
   */
  get banners(): BannerLink[] {
    if (!this.flowLineSettings) return [];
    const { maxBannerCount } = this;
    const menuBanners = normalizeHotelFlowLineSettings(
      this.flowLineSettings,
      this,
    ).banners.filter(({ pageIds }) => pageIds?.includes('menu'));

    if (!menuBanners.length) return this.brandBanner;
    return menuBanners.length > maxBannerCount
      ? limitArrayLength(menuBanners, maxBannerCount)
      : menuBanners;
  }

  /**
   * バナーリンクとコンポーネントpropsのセットのリスト
   *
   * @see {@link BannerWithProps}
   */
  get bannerWithProps(): BannerWithProps[] {
    const drawerBanners = this.hotel ? this.banners : this.brandBanner;

    return drawerBanners.map((banner) => {
      const banner670x170 = toBannerLinkProps(banner, '670x170').src;

      return {
        banner,
        media: banner670x170
          ? {
              '670x170': banner670x170,
            }
          : null,
      };
    });
  }

  /**
   * グローバルリンク
   */
  get linkMenus(): HDrawerMenuData[] {
    return [
      {
        key: 'termsOfuse',
        label: this.$t('label.termsOfuse') as string,
        href: this.$t('url.termsOfuse') as string,
        target: '_blank',
      },
      {
        key: 'privacyPolicy',
        label: this.$t('label.privacyPolicy') as string,
        href: `${this.$t('url.privacyPolicy')}`,
        target: '_blank',
      },
      {
        key: 'specialCommercialLaw',
        label: this.$t('label.specialCommercialLaw') as string,
        href: `${this.$t('url.specialCommercialLaw')}`,
        target: '_blank',
        /** ラベルとリンクが両方存在する時に表示させる */
        hidden: !(
          this.$t('label.specialCommercialLaw') &&
          this.$t('url.specialCommercialLaw')
        ),
      },
      {
        key: 'recruit',
        label: this.$t('label.recruitInfo') as string,
        href: this.$t('url.recruit') as string,
        target: '_blank',
      },
      {
        key: 'reit',
        label: this.$t('label.REITIntro') as string,
        href: this.$t('url.REIT') as string,
        target: '_blank',
      },
      {
        key: 'pressCenter',
        label: this.$t('label.pressCenter') as string,
        href: this.$t('url.pressCenter') as string,
        target: '_blank',
      },
    ];
  }

  private reservationToTop: boolean = false;

  protected onDrawerBeforeEnter() {
    if (this.reservationToTop) {
      this.reservationToTop = false;
      this.$nextTick(() => {
        this.toTop();
      });
    }
  }

  protected onDrawerAfterLeave() {
    this.reservationToTop = true;
  }

  get isActive() {
    return this.$ui.drawerIsActive;
  }

  set isActive(isActive: boolean) {
    this.$ui.setDrawerIsActive(isActive);
  }

  get offsetStyles() {
    return {
      top: this.$ui.originalHeaderHeight + 'px',
    };
  }

  toTop(duration = 0) {
    const { scroller } = this.$refs;
    if (!scroller) {
      return undefined;
    }
    if (duration === 0) {
      scroller.scrollTop = 0;
    } else {
      return scrollToTop({
        container: scroller,
        duration,
      });
    }
  }

  close() {
    this.isActive = false;
  }

  /**
   * グローバルメニュー
   */

  private showModal(name: MyModalName) {
    const { $refs } = this;
    if (!$refs) return;

    MY_MODALS.forEach((_name) => {
      const vm = this.$refs[_name];
      if (!vm) return;
      if (name === _name) {
        vm.show();
      } else {
        vm.close();
      }
    });
  }

  private closeModal() {
    const { $refs } = this;
    if (!$refs) return;

    MY_MODALS.forEach((_name) => {
      const vm = this.$refs[_name];
      vm && vm.close();
    });
  }

  get menus() {
    const menus: MyMenu[] = [
      {
        key: 'account',
        label: this.$t('label.signIn') as string,
        icon: 'user-outline',
        click: () => {
          if (this.$account.loggedIn) {
            return this.$account.openServiceUrl();
          }
          this.showModal('account');
        },
      },
      {
        key: 'globals',
        label: this.$t('label.settings') as string,
        icon: 'global',
        click: () => {
          if (this.$mq.match.narrow) {
            this.showModal('baloon');
          } else {
            this.showModal('globals');
          }
        },
      },
    ];
    return menus;
  }

  @Watch('$route')
  protected routeChangeHandler() {
    this.close();
  }
}

export const HDrawer = tsx
  .ofType<HDrawerProps, HDrawerEmits, HDrawerScopedSlots>()
  .convert(HDrawerRef);
