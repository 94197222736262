import './HNavigation.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes';
import {
  AvailableLanguage,
  NAVIGATION_PREFIX,
  NavigationModule,
} from '~/schemes';
import { HNavigationItem } from '~/components';
import { NavigationColor } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

interface NavSettings {
  color: string;
  bgColor: string;
}

const NAV_SETTINGS_MAP: Record<NavigationColor, NavSettings> = {
  default: {
    color: '#784147',
    bgColor: '#f1ebdb',
  },
  plain: {
    color: '#000',
    bgColor: '#e6e6e6',
  },
};

export const HNavigation = tsx.component({
  name: 'HNavigation',
  functional: true,

  props: {
    value: {
      type: Object as PropType<
        ResolvedLanugageData<NavigationModule['properties'], AvailableLanguage>
      >,
      required: true,
    },
  },

  render(h, { props }): VNode {
    const { layout, navColor, navigations } = props.value;
    // ナビゲーションカラー
    const navSettings = NAV_SETTINGS_MAP[navColor];
    const { color, bgColor } = navSettings;
    return (
      <div staticClass="v-navigation" class={`v-navigation--${layout}`}>
        <ul
          staticClass="v-navigation__list"
          class={`v-navigation__list--${layout}`}>
          {navigations.map(({ key, title, url }) => (
            <li
              key={key}
              staticClass="v-navigation__list-item"
              style={`--background:${bgColor}; --color:${color}`}>
              <HNavigationItem
                staticClass={`v-navigation__list-item__link--${layout}`}
                href={`#${NAVIGATION_PREFIX}${url}`}>
                {title}
              </HNavigationItem>
            </li>
          ))}
        </ul>
      </div>
    );
  },
});
