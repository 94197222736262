import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelFeature } from '~/schemes';
import {
  HSimpleGallery,
  HSimpleGalleryItemInput,
  HThemeDecoratedTitle,
  HBtn,
} from '~/components';

export interface MyHotelFeatureWideItemProps {
  data: HotelFeature;
  total: number;
  index: number;
  needPauseCarousel?: boolean;
}

export interface MyHotelFeatureWideItemEmits {}

export interface MyHotelFeatureWideItemScopedSlots {}
/**
 * リゾナーレの3つの特徴
 */
@Component<MyHotelFeatureWideItemRef>({
  name: 'MyHotelFeatureWideItem',
  render() {
    const { carouselItems, index, data, link } = this;
    return (
      <div
        staticClass="my-hotel-feature-wide-item"
        class={{
          'my-hotel-feature-wide-item--alternated': (index - 1) % 2 === 1,
        }}>
        <div staticClass="my-hotel-feature-wide-item__header h-container">
          <div staticClass="my-hotel-feature-wide-item__index">
            <span staticClass="my-hotel-feature-wide-item__index__label">
              Promise
            </span>
            <span staticClass="my-hotel-feature-wide-item__index__num">
              {index}
            </span>
          </div>
        </div>
        <HSimpleGallery
          staticClass="my-hotel-feature-wide-item__gallery"
          items={carouselItems}
          scopedSlots={{
            custom: ({ renderGallery, renderPagination }) => {
              const pagination = renderPagination();
              return [
                renderGallery({
                  staticClass: 'my-hotel-feature-wide-item__gallery__node',
                }),
                <div staticClass="my-hotel-feature-wide-item__footer h-container">
                  <div staticClass="my-hotel-feature-wide-item__pagination">
                    {pagination}
                  </div>
                  <div staticClass="my-hotel-feature-wide-item__content">
                    <HThemeDecoratedTitle
                      staticClass="my-hotel-feature-wide-item__content__title"
                      size="sm"
                      tag="h3">
                      {data.title}
                    </HThemeDecoratedTitle>
                    <div
                      v-wysiwyg={data.description}
                      staticClass="my-hotel-feature-wide-item__content__description h-theme-lead"
                    />
                    {!!link && (
                      <div staticClass="my-hotel-feature-wide-item__content__footer">
                        <HBtn
                          staticClass="my-hotel-feature-wide-item__content__footer__link"
                          props={{ ...link.props, moveIn: true }}>
                          {link.text}
                        </HBtn>
                      </div>
                    )}
                  </div>
                </div>,
              ];
            },
          }}
        />
      </div>
    );
  },
})
export class MyHotelFeatureWideItemRef
  extends Vue
  implements MyHotelFeatureWideItemProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelFeature;
  @Prop({ type: Number, required: true }) readonly total!: number;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Boolean }) readonly needPauseCarousel!: boolean;

  get carouselItems(): HSimpleGalleryItemInput[] {
    return this.data.images.map((image) => {
      const { key, main, square, meta } = image;
      return {
        key,
        url: this.$res.img(main),
        narrowURL: square && this.$res.img(square),
        meta,
        isEven: (this.index - 1) % 2 === 1,
      };
    });
  }

  get link() {
    const { link } = this.data;
    if (!link) return;
    const { url, text } = link;
    if (!text || !url) return;
    return {
      url,
      text,
      props: this.$navigation.resolveHrefTo(url),
    };
  }
}

export const MyHotelFeatureWideItem = tsx
  .ofType<
    MyHotelFeatureWideItemProps,
    MyHotelFeatureWideItemEmits,
    MyHotelFeatureWideItemScopedSlots
  >()
  .convert(MyHotelFeatureWideItemRef);
