import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { Component } from 'nuxt-property-decorator';
import HotelViewRoot from '../../-index';
import { MyHotelLocalNavi } from '../-components';
import {
  BannerLink,
  BannerWithProps,
  DisplayReservationCenter,
  HotelDetail,
  HotelNotification,
  MAX_BANNER_COUNT_MAP,
} from '~/schemes';
import {
  HBannerLink,
  HBtn,
  HIconLabel,
  HIconLink,
  HSimpleCoverTitle,
  IconName,
  toBannerLinkProps,
} from '~/components';
import { hour12, limitArrayLength } from '~/helpers';
import { BannerSettingPages } from '~/server-middleware/data-server/adapter/ACCOAdapter/@types';

export const SNS_TYPES = [
  'instagram',
  'twitter',
  'facebook',
  'youtube',
] as const;

export type SNSType = typeof SNS_TYPES[number];

export interface SNSItem {
  type: SNSType;
  icon: IconName;
  url: string;
  label: string;
}

/**
 * 星野リゾート公式アカウント
 */
export const DEFAULT_SNS_LIST: SNSItem[] = [
  {
    type: 'instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/hoshinoresorts.official/',
    label: '@hoshinoresorts.official',
  },
  {
    type: 'twitter',
    icon: 'twitter',
    url: 'https://twitter.com/HoshinoResorts',
    label: '@HoshinoResorts',
  },
  {
    type: 'facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/hoshinoresort/',
    label: 'https://www.facebook.com/hoshinoresort/',
  },
  {
    type: 'youtube',
    icon: 'youtube',
    url: 'https://www.youtube.com/hoshinoresortcom',
    label: '@hoshinoresortcom',
  },
];

@Component<MyView>({
  inject: ['hotelViewRoot'],
  async asyncData({ $hotel, params, error, $error }) {
    try {
      const { hotel_slug } = params;
      let hotel: HotelDetail | null | undefined = $hotel.current;
      if (!hotel) hotel = await $hotel.getBySlug(hotel_slug);
      if (!hotel) {
        return error({
          statusCode: 404,
        });
      }
    } catch (_err) {
      $error.throw(_err);
    }
  },
  head() {
    const { hotel, title } = this;
    const head: MetaInfo = {
      title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: title },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${this.$navigation.origin}/${this.$language.current}/hotels/${hotel.slug}/contact/`,
        },
      ],
    };
    return head;
  },
  render() {
    const {
      title,
      hotel,
      hrFaqUrl,
      computedReservationCenter,
      bannerWithProps,
      hotelInformations,
      snsList,
    } = this;
    return (
      <div staticClass="my-hotel-contact-index-view">
        {/* ナビゲーション */}
        <MyHotelLocalNavi />
        {/* コンテナ */}
        <div staticClass="my-container">
          {/* タイトル */}
          <div staticClass="my-head">
            <HSimpleCoverTitle class="my-head__title" title={title} />
          </div>

          {/* 所在地 */}
          {!!hotel.address && (
            <div staticClass="my-location">
              <HIconLabel
                staticClass="my-location__label"
                name="map-marker-outline">
                {this.$t('label.address')}
              </HIconLabel>
              {hotel.mapUrl ? (
                <a
                  staticClass="my-location__address"
                  href={hotel.mapUrl}
                  target="_blank"
                  rel="noopener"
                  domPropsInnerHTML={
                    this.$t('value.address', hotel) as string
                  }></a>
              ) : (
                <span
                  staticClass="my-location__address"
                  domPropsInnerHTML={
                    this.$t('value.address', hotel) as string
                  }></span>
              )}
            </div>
          )}

          {/* お困りですか? */}
          <div staticClass="my-need-help">
            <HIconLabel
              staticClass="my-need-help__label"
              name="question-outline">
              {this.$t('label.help')}
            </HIconLabel>
            <div staticClass="my-need-help__items">
              <HBtn
                staticClass="my-need-help__item"
                color="plain"
                stackIcon="question-outline"
                prependStack
                href={hrFaqUrl}
                target="_blank">
                {this.$t('label.faq')}
              </HBtn>
            </div>
          </div>

          {/* 予約センター情報 */}
          {!!computedReservationCenter && (
            <div staticClass="my-reservation-center">
              <HIconLabel
                staticClass="my-reservation-center__label"
                name="phone-outline">
                {this.$t('label.phone')}
              </HIconLabel>
              {/* 予約センター情報 */}
              {computedReservationCenter}
            </div>
          )}

          {/* メルマガ登録(日本語以外は非表示) */}
          {this.$language.current === 'ja' && (
            <div staticClass="my-mail-magazine">
              <h4 staticClass="my-mail-magazine__title">
                星野リゾート メールマガジン
              </h4>
              <p staticClass="my-mail-magazine__sub-title">
                ここでしか読めない、旅を楽しくするヒントを受け取りませんか？
              </p>
              <HBtn
                staticClass="my-mail-magazine__register"
                color="plain"
                href={this.$t('url.mailMagazine') as string}
                target="_blank">
                メールマガジン登録に進む
              </HBtn>
            </div>
          )}

          {/* バナーリンクエリア */}
          {(!!bannerWithProps.length || !!hotelInformations.length) && (
            <div staticClass="my-banner-link">
              {!!bannerWithProps.length && (
                <div staticClass="my-banner-link__banners">
                  {bannerWithProps.map(({ banner, media }) => (
                    <HBannerLink
                      key={banner.key}
                      props={{
                        ...banner,
                        excludeDecoration: true,
                      }}
                      staticClass="my-banner-link__banners__item"
                      style={
                        media
                          ? {
                              '--image-335x85': `url(${media['670x170']})`,
                            }
                          : { '--banner-image': 'gray' }
                      }
                    />
                  ))}
                </div>
              )}
              {/* テキストリンクエリア */}
              {!!hotelInformations.length && (
                <div staticClass="my-banner-link__links">
                  {hotelInformations.map(({ title, link }) => (
                    <HIconLink
                      staticClass="my-banner-link__links__item"
                      icon="arrow-right"
                      href={(link && link.url) || ''}
                      target={link && link.blank ? '_blank' : '_self'}
                      rel="noopener"
                      hiddenExternalIcon>
                      {title}
                    </HIconLink>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* SNS */}
          <div staticClass="my-sns-list">
            {!!snsList &&
              snsList.length > 0 &&
              snsList.map((sns) => (
                <HIconLink
                  staticClass="my-sns-list__item"
                  class={`my-sns-list__item--${sns.type}`}
                  icon={sns.icon}
                  href={sns.url}
                  target="_blank"
                  rel="noopener"
                  hiddenExternalIcon></HIconLink>
              ))}
          </div>
        </div>
      </div>
    );
  },
})
export default class MyView extends Vue {
  readonly hotelViewRoot!: HotelViewRoot;

  /** 表示するバナーの最大数 */
  get maxBannerCount(): number {
    return MAX_BANNER_COUNT_MAP.contact;
  }

  get title() {
    return this.$t('name.contact') as string;
  }

  get hotel() {
    return this.hotelViewRoot.hotel;
  }

  get brand() {
    return (this.hotel && this.hotel.brand) || this.$hotel.currentBrand;
  }

  /**
   * 予約センター情報
   */
  get reservationCenter() {
    return this.$hotel.currentReservationCenter;
  }

  /**
   * 予約センター注記
   */
  get reservationCenterNotice() {
    return this.$hotel.currentReservationCenterNotice;
  }

  get hrFaqUrl() {
    const url = this.$t('url.faq');
    if (typeof url === 'string') return url;
  }

  /**
   * 予約の電話番号表示
   */
  get computedReservationCenter() {
    const { reservationCenter, hotel } = this;

    if (!reservationCenter) return;

    const { jp, overseas } = reservationCenter;

    /**
     * メイン
     * - 予約センター情報
     */
    let main: DisplayReservationCenter | null = null;

    /**
     * 予約センター情報
     *
     * 表示言語が日本語の場合、国内予約受付番号が代入
     * それ以外の場合、国外予約受付番号が代入される
     */
    const phoneInformation = this.$language.current === 'ja' ? jp : overseas;

    if (phoneInformation) {
      main = {
        name: this.$t('label.reservationCenter') as string,
        number: phoneInformation.number,
        from: phoneInformation.from,
        to: phoneInformation.to,
        times: `${hour12(phoneInformation.from)} - ${hour12(
          phoneInformation.to,
        )}`,
        notice: this.reservationCenterNotice,
      };
    }

    /**
     * サブ
     * - ホテル詳細の問い合わせに登録された問い合わせ先情報のこと
     */
    const subs: DisplayReservationCenter[] = hotel.contacts.map((contact) => {
      return {
        name: contact.name,
        number: contact.tel,
        from: contact.from,
        to: contact.to,
        times: `${hour12(contact?.from)} - ${hour12(contact.to)}`,
        notice: contact.memo,
      };
    });

    const reservationCenters: DisplayReservationCenter[] = main
      ? [main, ...subs]
      : [...subs];

    return reservationCenters.map(({ name, number, times, notice }) => {
      return (
        number && [
          <div staticClass="my-reservation-center-inner">
            <dl staticClass="my-reservation-center-info">
              <dt staticClass="my-reservation-center-info__header">{name}</dt>
              <dd staticClass="my-reservation-center-info__tel">
                <a
                  staticClass="my-reservation-center-info__tel__label"
                  href={`tel:${number}`}>
                  {number}
                </a>
              </dd>
              <dd staticClass="my-reservation-center-info__times">
                <span staticClass="my-reservation-center-info__times__label">
                  {/* eslint-disable-next-line no-irregular-whitespace */}
                  {this.$t('label.open')}　{times}
                </span>
              </dd>
              {!!notice && (
                <dd staticClass="my-reservation-center-info__notice">
                  <small>{notice}</small>
                </dd>
              )}
            </dl>
          </div>,
        ]
      );
    });
  }

  get routeName() {
    const routeName = this.hotelViewRoot.currentRouteName;
    if (!routeName) throw new Error('missing routeName');
    return routeName.split('-').pop()! as BannerSettingPages;
  }

  /**
   * ブランドバナー情報
   * @remarks
   * - 最大2つのブランドバナーを返却する
   */
  get brandBanner(): BannerLink[] {
    const { brand, maxBannerCount } = this;
    if (!brand) return [];
    const brandBanners = brand.banners as BannerLink[];
    return brandBanners.length > maxBannerCount
      ? limitArrayLength(brandBanners, maxBannerCount)
      : brandBanners;
  }

  /**
   * 連絡先バナー情報
   * @remarks
   * - 最大2つのコンタクトバナーを返却する
   */
  get banners(): BannerLink[] {
    const { maxBannerCount, routeName } = this;
    const contactBanners = this.hotelViewRoot.normalizedFlowLineSettings.banners.filter(
      ({ pageIds }) => pageIds?.includes(routeName),
    );
    if (!contactBanners.length) return this.brandBanner;
    return contactBanners.length > maxBannerCount
      ? limitArrayLength(contactBanners, maxBannerCount)
      : contactBanners;
  }

  /**
   * バナーリンクとコンポーネントpropsのセットのリスト
   * - バナーに連絡先ページのID(contact)が設定されている場合に最大2つのバナーを返却する
   *
   * @see {@link BannerWithProps}
   */
  get bannerWithProps(): BannerWithProps[] {
    return this.banners.map((banner) => {
      const banner670x170 = toBannerLinkProps(banner, '670x170').src;

      return {
        banner,
        media: banner670x170
          ? {
              '670x170': banner670x170,
            }
          : null,
      };
    });
  }

  /**
   * 施設からのお知らせ
   */
  get hotelInformations(): HotelNotification[] {
    return this.hotel.hotelInformations || [];
  }

  /**
   * SNS情報
   */
  get snsList(): SNSItem[] {
    const { hotel } = this;
    if (!hotel) return [];
    const list = DEFAULT_SNS_LIST;

    // 自施設のSNSで上書き
    list.forEach((sns) => {
      const value = hotel[sns.type];
      if (value) {
        if (sns.type === 'facebook') {
          sns.url = `https://www.facebook.com/${value}`;
          sns.label = sns.url;
        } else if (sns.type === 'instagram') {
          sns.url = `https://www.instagram.com/${value}/`;
          sns.label = `@${value}`;
        } else if (sns.type === 'twitter') {
          sns.url = `https://twitter.com/${value}`;
          sns.label = `@${value}`;
        } else if (sns.type === 'youtube') {
          sns.url = `https://www.youtube.com/${value}`;
          sns.label = `@${value}`;
        } else {
          throw new Error('missing sns type');
        }
      }
    });
    return list;
  }
}
