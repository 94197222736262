import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  HotelDetailInfo,
  BannerLink,
  NormalizedHotelFlowLineSettings,
  AnyMediaSource,
  BannerWithProps,
  MAX_BANNER_COUNT_MAP,
} from '~/schemes';
import { HBannerLink, toBannerLinkProps, HIcon } from '~/components';
import { URLParsedResult } from '~/plugins/navigation';
import { limitArrayLength } from '~/helpers';

interface KaiBanner {
  link: URLParsedResult;
  banner: BannerLink;
  imageSrc: AnyMediaSource | null | undefined;
}

export interface MyHotelFlowLineProps {
  hotel: HotelDetailInfo;
  settings: NormalizedHotelFlowLineSettings;
}

export interface MyHotelFlowLineEmits {}

export interface MyHotelFlowLineScopedSlots {}

@Component<MyHotelFlowLineRef>({
  name: 'MyHotelFlowLine',
  render() {
    const { bannerWithProps, kaiBanners } = this;

    /** 界のふんわりバナー用
     @TODO 現状は暫定対応。登録は2つのみ（過ごし方とアクティビティのみの想定）。界用バナーデザインができたらここに書くのかどうかも含めてリファクタする */
    if (kaiBanners)
      return (
        <div
          class={[
            'my-hotel-flow-line-kai',
            `my-hotel-flow-line-kai--banners-${kaiBanners.length}`,
          ]}>
          {kaiBanners.map(({ banner, link, imageSrc }) => {
            return this.$createElement(
              link.TagName,
              {
                key: banner.key,
                staticClass: 'my-hotel-flow-line-kai__item',
                props: link.props,
                attrs: link.attrs,
              },
              [
                <div
                  staticClass="my-hotel-flow-line-kai__item__img"
                  style={{
                    backgroundImage: `url(${imageSrc})`,
                  }}
                />,
                <div staticClass="my-hotel-flow-line-kai__item__body">
                  <h3 staticClass="my-hotel-flow-line-kai__item__ttl">
                    {banner.title}
                  </h3>
                  <HIcon
                    staticClass="my-hotel-flow-line-kai__item__icon"
                    name="arrow-right"
                  />
                </div>,
              ],
            );
          })}
        </div>
      );
    return (
      /* 四角いバナー用 */
      <div
        class={[
          'my-hotel-flow-line',
          `my-hotel-flow-line--banners-${this.banners.length}`,
        ]}>
        {bannerWithProps.map(({ banner, media }) => (
          <HBannerLink
            key={banner.key}
            props={banner}
            staticClass="my-hotel-flow-line__banner"
            style={
              media
                ? {
                    '--image-lg': `url(${media.lg})`,
                    '--image-md': `url(${media.md})`,
                    '--image-sm': `url(${media.sm})`,
                  }
                : { '--banner-image': 'gray' }
            }
          />
        ))}
      </div>
    );
  },
})
export default class MyHotelFlowLineRef
  extends Vue
  implements MyHotelFlowLineProps {
  @Prop({ type: Object, required: true }) readonly hotel!: HotelDetailInfo;
  @Prop({ type: Object, required: true })
  readonly settings!: NormalizedHotelFlowLineSettings;

  /** 表示するバナーの最大数 */
  get maxBannerCount(): number {
    return MAX_BANNER_COUNT_MAP.top;
  }

  /**
   * 正規化済みのメニューバナー情報
   * @remarks
   * - 最大2つのTOPバナーを返却する
   */
  get banners(): BannerLink[] {
    const { maxBannerCount } = this;
    const topBanners = this.settings.banners.filter(({ pageIds }) =>
      pageIds?.includes('top'),
    );
    return topBanners.length > maxBannerCount
      ? limitArrayLength(topBanners, maxBannerCount)
      : topBanners;
  }

  /**
   * バナーリンクとコンポーネントpropsのセットのリスト
   *
   * @see {@link BannerWithProps}
   */
  get bannerWithProps(): BannerWithProps[] {
    return this.banners.map((banner) => {
      const lg = toBannerLinkProps(banner, 'lg').src;
      const md = toBannerLinkProps(banner, 'md').src;
      const sm = toBannerLinkProps(banner, 'sm').src;

      return {
        banner,
        media:
          lg || md || sm
            ? {
                lg,
                md,
                sm,
              }
            : null,
      };
    });
  }

  /**
   * 界はふんわりバナーのデザインを適用させたいため、別途対応
   *
   * これがある時だけ界用のバナーを表示する
   */
  get kaiBanners(): KaiBanner[] | undefined {
    if (!this.$theme.is('kai')) return;
    const kaiBanners: KaiBanner[] = [];

    this.bannerWithProps.forEach(({ banner, media }) => {
      const bannerLink = banner.link;
      const url = bannerLink && bannerLink.url;
      if (!url) return;
      const link = this.$navigation.parseURL(url);
      const imageSrc = media && media.lg;
      const kaiBanner: KaiBanner = { banner, link, imageSrc };

      kaiBanners.push(kaiBanner);
    });

    return kaiBanners;
  }
}

export const TypedMyHotelFlowLine = tsx
  .ofType<
    MyHotelFlowLineProps,
    MyHotelFlowLineEmits,
    MyHotelFlowLineScopedSlots
  >()
  .convert(MyHotelFlowLineRef);
