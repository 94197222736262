import { CreateElement } from 'vue';
import { HPartition } from './modules/HPartition';
import { HSimpleTable } from './modules/HSimpleTable';
import { HHeading } from './modules/HHeading';
import { HLeadText } from './modules/HLeadText';
import { HText } from './modules/HText';
import {
  HAttractiveIntroduction,
  HAttractiveIntroductionList,
} from './modules/HAttractiveIntroduction';
import {
  HHotelIntroduction,
  HHotelIntroductionList,
} from './modules/HHotelIntroduction';
import { HMedia } from './modules/HMedia';
import {
  HHotelWithAttractions,
  HHotelWithAttractionsList,
} from './modules/HHotelWithAttractions';
import { HCustomHTML } from './modules/HCustomHTML';
import { HEmbed } from './modules/HEmbed';
import { HEmbedForm } from './modules/HEmbedForm';
import { HNavigation } from './modules/HNavigation';
import { HLinkButton, HLinkButtonList } from './modules/HLinkButton';
import { HProfile } from './modules/HProfile';
import { HBanner } from './modules/HBanner';
import { HEmbedMap } from './modules/HEmbedMap';
import { HRichTable } from './modules/HRichTable';
import { ResolvedLanugageData } from '~/server-middleware/data-server/adapter/ACCOAdapter/schemes/language';
import { HKeyVisualModule } from '~/components';
import {
  AvailableLanguage,
  CustomHTMLModule,
  KeyVisualModule,
  HeadingModule,
  LeadTextModule,
  HotelIntroductionModule,
  HotelIntroductionListModule,
  AttractiveIntroductionModule,
  AttractiveIntroductionListModule,
  HotelWithAttractionsModule,
  HotelWithAttractionsListModule,
  PartitionModule,
  MediaModule,
  SimpleTableModule,
  EmbedModule,
  EmbedFormModule,
  NavigationModule,
  LinkButtonModule,
  LinkButtonListModule,
  ProfileModule,
  BannerModule,
  EmbedMapModule,
  RichTableModule,
  TextModule,
} from '~/schemes';

export const MODULE_MAPPINGS = {
  CustomHTML: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      CustomHTMLModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HCustomHTML html={properties.html} />;
  },
  KeyVisual: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      KeyVisualModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HKeyVisualModule properties={properties} />;
  },
  Heading: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      HeadingModule['properties'],
      AvailableLanguage
    >,
  ) => {
    /**
     * @TODO: HHeadingというコンポーネント名は被りやすいので後々良い名称を考える。
     * 特にコンポーネント名に伴って決まるCSSセレクタ名はグローバルなので心配。
     */
    return <HHeading value={properties} />;
  },
  LeadText: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      LeadTextModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HLeadText value={properties} />;
  },
  // テキストモジュール
  Text: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      TextModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HText value={properties} />;
  },
  HotelIntroduction: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      HotelIntroductionModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HHotelIntroduction value={properties} />;
  },
  HotelIntroductionList: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      HotelIntroductionListModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HHotelIntroductionList value={properties} />;
  },
  AttractiveIntroduction: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      AttractiveIntroductionModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HAttractiveIntroduction value={properties} />;
  },
  AttractiveIntroductionList: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      AttractiveIntroductionListModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HAttractiveIntroductionList value={properties} />;
  },
  HotelWithAttractions: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      HotelWithAttractionsModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HHotelWithAttractions value={properties} />;
  },
  HotelWithAttractionsList: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      HotelWithAttractionsListModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HHotelWithAttractionsList value={properties} />;
  },
  Partition: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      PartitionModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HPartition value={properties} />;
  },
  Media: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      MediaModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HMedia value={properties} />;
  },
  SimpleTable: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      SimpleTableModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HSimpleTable value={properties} />;
  },
  Embed: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      EmbedModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HEmbed value={properties} />;
  },
  EmbedForm: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      EmbedFormModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HEmbedForm value={properties} />;
  },
  Navigation: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      NavigationModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HNavigation value={properties} />;
  },
  LinkButton: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      LinkButtonModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HLinkButton value={properties} />;
  },
  LinkButtonList: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      LinkButtonListModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HLinkButtonList value={properties} />;
  },
  Profile: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      ProfileModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HProfile value={properties} />;
  },
  Banner: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      BannerModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HBanner value={properties} />;
  },
  EmbedMap: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      EmbedMapModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HEmbedMap html={properties.html} />;
  },
  RichTable: (
    h: CreateElement,
    properties: ResolvedLanugageData<
      RichTableModule['properties'],
      AvailableLanguage
    >,
  ) => {
    return <HRichTable html={properties.html} />;
  },
};
