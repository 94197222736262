import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MyHotelFeatureItemTitle } from './MyHotelFeatureItemTitle';
import { HotelFeature, MediaInfoMeta } from '~/schemes';
import {
  HSimpleCarousel,
  HSimpleCarouselItem,
  HBtn,
  HPeepMedia,
  HSimpleDotPagination,
} from '~/components';

export interface HotelFeatureItemImage {
  image: string;
  meta?: MediaInfoMeta;
}

export interface MyHotelFeatureItemProps {
  data: HotelFeature;
  total: number;
  index: number;
  needPauseCarousel?: boolean;
}

export interface MyHotelFeatureItemEmits {}

export interface MyHotelFeatureItemScopedSlots {}

@Component<MyHotelFeatureItemRef>({
  name: 'MyHotelFeatureItem',
  render() {
    const {
      isSingle,
      carouselItems,
      needPauseCarousel,
      index,
      total,
      data,
      images,
      link,
    } = this;

    return (
      <div staticClass="my-hotel-feature-item">
        {this.isHoshinoyaTheme && (
          <HSimpleDotPagination
            staticClass="my-hotel-feature-item__pagination"
            items={images}
          />
        )}
        {!isSingle && !this.isHoshinoyaTheme && !this.isKaiTheme && (
          <HSimpleCarousel
            staticClass="my-hotel-feature-item__carousel"
            items={carouselItems}
            paused={needPauseCarousel}
          />
        )}
        {/* 画像が1枚のみ登録されている場合、画像の表示は静止した状態にする */}
        {isSingle && !this.isKaiTheme && !this.isHoshinoyaTheme && (
          <HPeepMedia
            staticClass="my-hotel-feature-item__single-img"
            src={images[0]}
            reverse={index % 2 === 0}
          />
        )}

        <div staticClass="my-hotel-feature-item__body">
          <MyHotelFeatureItemTitle
            index={index}
            total={total}
            data={data}
            needCount={!this.isHoshinoyaTheme}
          />

          <div staticClass="my-hotel-feature-item__body__inner">
            {/* 界の表示 */}
            {this.isKaiTheme && (
              <HPeepMedia
                staticClass="my-hotel-feature-item__single-img"
                src={images[0]}
                reverse={index % 2 === 0}
              />
            )}

            <div
              v-wysiwyg={data.description}
              staticClass="my-hotel-feature-item__description"
            />
            {/* 3つのおすすめのリンクボタン */}
            {!!link && (
              <div staticClass="my-hotel-feature-item__link">
                <HBtn
                  props={{
                    ...link.props,
                    color: 'plain',
                    block: true,
                  }}>
                  {link.text}
                </HBtn>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
})
export class MyHotelFeatureItemRef
  extends Vue
  implements MyHotelFeatureItemProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelFeature;
  @Prop({ type: Number, required: true }) readonly total!: number;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Boolean }) readonly needPauseCarousel!: boolean;

  get isKaiTheme() {
    return this.$theme.current.name === 'kai';
  }

  get isHoshinoyaTheme() {
    return this.$theme.current.name === 'hoshinoya';
  }

  get images(): HotelFeatureItemImage[] {
    return (this.data.images || []).map(({ main, meta }) => {
      return {
        image: this.$res.img(main),
        meta,
      };
    });
  }

  get isSingle() {
    return this.images.length === 1;
  }

  get carouselItems(): HSimpleCarouselItem[] {
    return this.images.map((image) => {
      return {
        images: [image],
      };
    });
  }

  get link() {
    const { link } = this.data;
    if (!link) return;
    const { url, text } = link;
    if (!text || !url) return;
    return {
      url,
      text,
      props: this.$navigation.resolveHrefTo(url),
    };
  }
}

export const MyHotelFeatureItem = tsx
  .ofType<
    MyHotelFeatureItemProps,
    MyHotelFeatureItemEmits,
    MyHotelFeatureItemScopedSlots
  >()
  .convert(MyHotelFeatureItemRef);
